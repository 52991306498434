import React from 'react'

//UI
import './termsScreen.scss'
import { YondrLogoTypo } from '../../resources/Logos'

function termsScreen() {
	return (
		<>
			<section className='section' id='terms'>
				<div className='container'>
					<YondrLogoTypo className='yondr-logo-typo --mobile' />
					<h1>Términos y Condiciones</h1>
					<p>
						Lea atentamente estas condiciones generales de acceso y utilización
						de la Aplicación YONDR.
					</p>
					<p>
						Definen las condiciones y restricciones de utilización que usted
						acepta cuando descarga, acceda y utiliza la Aplicación YONDR y los
						servicios, incluyendo las limitaciones de garantía y
						responsabilidad.
					</p>
					<p>
						Usted reconoce que estas condiciones generales son efectivas del
						mismo modo que cualquier otro contrato escrito y que se compromete a
						respetarlas.
					</p>
					<p>
						En su defecto, no está autorizado a descargar y utilizar la
						Aplicación móvil YONDR (“Aplicación YONDR"). Las condiciones
						generales se pueden consultar en cualquier momento en la Aplicación
						YONDR y en la página web https://yondr.es/.
					</p>
					<h2> Artículo 1 - Definiciones </h2>
					<p>
						<b>Aplicación YONDR: </b> aplicación publicada por La Compañía cuyo
						uso está sujeto a estas Condiciones Generales, incluyendo sus
						actualizaciones posteriores.
					</p>
					<p>
						<b>Cuenta de usuario: </b> cuenta creada por el Usuario en la
						Aplicación YONDR y accesible con una dirección de correo electrónico
						y una contraseña para acceder y utilizar los Servicios.
					</p>
					<p>
						<b>Condiciones Generales o Contrato:</b> las presentes Condiciones
						Generales se aplican al acceso y a la utilización de la Aplicación
						YONDR y sus funciones y demás servicios de La Compañía para el
						Usuario.
					</p>
					<p>
						<b>Servicios: </b>servicios ofrecidos en la Aplicación YONDR tal
						como descritos en el artículo 4 y en la Aplicación YONDR, así como
						los demás servicios ofrecidos por La Compañía al Usuario.
					</p>
					<p>
						<b>Tienda Online: </b>plataforma de descarga de aplicaciones móviles
						que permite la descarga de la Aplicación YONDR para el sistema
						operativo utilizado en el terminal del Usuario.
					</p>
					<p>
						<b>Terminal: </b>cualquier teléfono inteligente conectado a Internet
						y con un sistema operativo compatible con la Aplicación YONDR, tal
						como se indica en la Tienda Online, de los cuales el Usuario es
						propietario único o tiene el control y en el cual la Aplicación
						YONDR está instalada y utilizada.
					</p>
					<p>
						<b>La Compañía: </b> empresa de software que publica la Aplicación
						YONDR y presta los Servicios, cuyos detalles se indican al final de
						las Condiciones Generales.
					</p>
					<p>
						<b>Usuario: </b> persona física que ha descargado y que utiliza la
						Aplicación YONDR en su Terminal para sus necesidades personales que
						se compromete a respetar las Condiciones Generales.
					</p>
					<h2>Artículo 2 - Objeto </h2>
					<p>
						Las Condiciones Generales definen las condiciones bajo las cuales el
						Usuario tiene el derecho a utilizar la Aplicación YONDR así como las
						responsabilidades de La Compañía y del Usuario.
					</p>
					<p>
						Al hacer click en CREAR CUENTA, el Usuario acepta sin reservas las
						Condiciones Generales y reconoce que prevalecen sobre cualquier otro
						documento relativo a la Aplicación YONDR.
					</p>
					<p>
						La Compañía podrá en cualquier momento modificar, suspender o
						eliminar la Aplicación YONDR o los Servicios y/o modificar las
						Condiciones Generales. Estas acciones no tendrán impacto en la
						Aplicación YONDR descargada previamente. La aceptación de las nuevas
						Condiciones Generales y/o la continuación del uso de la Aplicación
						YONDR después de la entrada en vigor de las modificaciones
						constituyen la aceptación de las nuevas condiciones.
					</p>
					<p>
						El Contrato se celebra entre La Compañía y el Usuario excluyendo el
						operador de la Tienda Online que no es responsable de la Aplicación
						YONDR y sus contenidos. El Usuario se compromete también a cumplir
						con todas las obligaciones relacionadas con la descarga de la
						Aplicación YONDR en la Tienda Online. El Usuario reconoce que el
						operador de la tienda y sus filiales tiene la condición de
						beneficiarios de este Contrato y tendrá la posibilidad de oponerse
						al Usuario si necesario.
					</p>
					<h2>Artículo 3 - Acceso a la Aplicación y Creación de Cuenta </h2>
					<p>
						<b>3.1. Acceso a la Aplicación YONDR: </b>Las funciones de la
						Aplicación YONDR son accesibles, después de la descarga en la Tienda
						Online y la creación de una cuenta de Usuario.
					</p>
					<p>
						El Usuario deberá disponer de un sistema (hardware y software)
						compatible con la Aplicación YONDR y un acceso a Internet que están
						a su cargo y bajo su responsabilidad. La utilización de la
						Aplicación YONDR puede requerir actualizaciones periódicas.
					</p>
					<p>
						<b>3.2. Manifestaciones. </b> El Usuario manifiesta y garantiza a La
						Compañía que:
						<ul>
							<li>
								Los usuarios pertenecientes a la Unión Europea deben tener una
								edad mínima de 16 años. En el caso de los usuarios menores de
								dicha edad, serán los padres o tutores legales los que deban
								declarar su consentimiento para el uso y tratamiento de los
								datos del menor o dar su aprobación para ello.
							</li>
							<li>
								No se encuentra en un país sujeto a un embargo por el gobierno
								de los Estados Unidos designado como apoyo al terrorismo o que
								no figura en una lista de personas sujeto a restricciones o
								prohibiciones establecidas por el gobierno de los Estados
								Unidos.
							</li>
						</ul>
					</p>
					<p>
						<b>3.3. Creación de una Cuenta de Usuario: </b>La Cuenta de Usuario
						permite al Usuario gestionar su perfil y acceder a los Servicios.
					</p>
					<p>
						El Usuario debe crear una Cuenta de Usuario en la Aplicación YONDR
						usando su dirección de correo electrónico y una contraseña. El
						Usuario manifiesta que toda información que aporta a la aplicación
						YONDR es completa, veraz y actualizada en cada momento.
					</p>
					<p>
						El Usuario reconoce que sus credenciales y su contraseña son
						estrictamente personales y confidenciales. Está prohibido, por lo
						tanto, comunicarlos o compartirlos con terceros. El Usuario es el
						único responsable de la utilización de su Cuenta de Usuario, así
						como de todas acciones y declaraciones realizadas a través de su
						Cuenta de Usuario.
					</p>
					<p>
						<b>
							En ningún caso La Compañía se hace responsable en caso de
							usurpación de la identidad de un usuario dado que no dispone de
							medios técnicos que permiten de asegurarse de manera sistemática
							de la identidad de las personas.
						</b>
					</p>
					<p>
						Se le pedirá al Usuario rellenar las informaciones solicitadas. Es
						decir, concretamente su edad, su altura, su peso y su género.
					</p>
					<p>
						El Usuario puede también comunicar de forma espontánea cierta
						información en el ámbito de la utilización de la Aplicación YONDR,
						especialmente en relación con sus actividades, su peso y sus
						comidas.
					</p>
					<p>
						Para aprovechar ciertas funciones y Servicios ofrecidos por la
						Aplicación YONDR, el Usuario puede autorizar la Aplicación YONDR
						para acceder al rastreo de su actividad llevado a cabo en su
						Terminal, gestionando estos parámetros en el menú de su Terminal.
					</p>
					<p>
						Las informaciones comunicadas a La Compañía son recogidas y tratadas
						de acuerdo con la política de confidencialidad que se detalle en el
						artículo 9.
					</p>
					<p>
						El Usuario garantiza que los datos facilitados en el momento de la
						inscripción y posteriormente, con carácter obligatorio u opcional
						son precisas, sinceras y conformes a la realidad.
					</p>
					<p>
						<b>
							La Compañía en ningún caso será responsable de la aportación de
							información falsa o incompleta por el usuario o de sus
							consecuencias.
						</b>
					</p>

					<h2>Artículo 4 - Funciones de la Aplicación</h2>
					<p>
						Todas las funciones de la Aplicación YONDR son accesibles
						gratuitamente.
					</p>
					<p>
						La Aplicación YONDR y/o Sitio Web puede incluir enlaces a otros
						servicios, páginas web, aplicaciones, incluyendo publicidad de
						terceros realizada en nuestro servicio. No somos responsables de
						dichos servicios de terceros y no realizamos ninguna manifestación o
						garantía al respecto. El usuario acepta que La Compañía no será
						responsable frente a cualesquiera daños o pérdidas que pueda sufrir
						el Usuario con respecto al acceso o uso de los servicios enlazados
						desde el Sitio Web o la Aplicación YONDR.
					</p>
					<h2>Artículo 5 - Obligaciones del Usuario</h2>
					<p>
						El Usuario acepta y se compromete a no hacer un uso de la Aplicación
						YONDR que pueda ir en perjuicio de La Compañía, del funcionamiento
						de la Aplicación YONDR, de terceros o de las leyes en vigor y estas
						Condiciones Generales.
					</p>
					<p>
						El Usuario no publicará en o a través de la Aplicación YONDR ningún
						contenido que infringe las leyes y la legalidad vigentes, ni los
						derechos de terceros. La Compañía se reserva el derecho de eliminar
						cualquier contenido de Usuario en cualquier momento, con o sin
						motivo. Cada usuario será único responsable de la información que
						publique en/ a través de la Aplicación YONDR y nos garantiza que
						dicha información es veraz, actualizada y no infringe ningún derecho
						de tercero ni está cubierto por obligaciones de confidencialidad. El
						Usuario concede a La Compañía una licencia gratuita y perpetua, con
						alcance mundial, para reproducir y comunicar públicamente esta
						información a través de la Aplicación YONDR y el Sitio Web, así como
						transformarlo en la manera necesaria para ello. En caso de que un
						Usuario considere que un contenido disponible en la App o Sitio Web
						infrinja sus derechos o estas condiciones, puede contactar con La
						Compañía en la dirección de correo electrónico indicada. Podemos
						iniciar una investigación, y compartir información relevante con las
						autoridades.
					</p>
					<p>
						El Usuario acepta y se compromete a no hacer un uso de la Aplicación
						YONDR que pueda ir en perjuicio de La Compañía, del funcionamiento
						de la Aplicación YONDR, de terceros o de las leyes en vigor y estas
						Condiciones Generales.
					</p>
					<h2>Artículo 6 - Propiedad Intelectual</h2>
					<p>
						La Compañía es titular de todos los derechos y/o explota legalmente
						la Aplicación YONDR y sus contenidos respecto al Usuario. El Usuario
						reconoce que los patentes, marcas registradas, modelos, derechos de
						autor u otros derechos de propiedad intelectual relativos a la
						Aplicación YONDR, incluyendo todas las modificaciones, traducciones,
						adaptaciones, mejoras, correcciones, actualizaciones o nuevas
						versiones y obras derivadas, están y seguirán reservados en todo
						momento a La Compañía.
					</p>
					<p>
						El Usuario se compromete a utilizar la Aplicación YONDR según las
						Condiciones Generales y a no vulnerar de manera alguna los derechos
						de propiedad de La Compañía. Tal vulneración puede dar lugar a
						acciones legales y la resolución de este Contrato.
					</p>
					<p>
						La Compañía le otorga una licencia limitada, no transferible, no
						revocable, exclusiva, para ver, descargar, copiar e imprimir los
						contenidos recuperados de los Servicios únicamente para su uso
						personal y no comercial, siempre que no retire u oscurezca cualquier
						aviso de copyright, aviso de marca comercial u otros avisos de
						derechos de propiedad que aparecen en o relacionados con los
						Contenidos. Aunque nos esforzamos por mantener los Contenidos
						exactos, completos y actualizados, no podemos y no garantizamos, ni
						somos responsables de la exactitud, integridad ni puntualidad de
						ningún contenido, ya sea proporcionado por nosotros o por nuestros
						afiliados o por otros usuarios.
					</p>
					<p>
						Sujeto al cumplimiento de las Condiciones Generales, La Compañía
						concede al Usuario una licencia personal, limitada y temporal, no
						transferible, no exclusiva, descargar, para instalar y utilizar la
						Aplicación YONDR en su Terminal, en forma ejecutable. Se prohíbe
						cualquier uso no autorizado en las Condiciones Generales.
					</p>
					<p>
						En particular pero sin limitaciones, se prohibe al Usuario:
						<ul>
							<li>
								Instalar la Aplicación YONDR y/o utilizarla a fines distintos a
								los especificados en este Contrato, y concretamente a fines
								ilegales o comerciales;
							</li>
							<li>
								copiar, reproducir, modificar en forma alguna, integrar en otro
								producto, parte o la totalidad de la Aplicación YONDR incluso
								para corregir los errores que pueda contener, este derecho
								deberá reservarse para La Compañía salvo de las copias
								autorizadas por la ley;
							</li>
							<li>
								desensamblar, ni aplicar ingeniería inversa, tratar de descubrir
								el código fuente (estrictamente confidencial) y/o los archivos
								de datos específicos de la Aplicación YONDR, incluyendo para
								hacerla interoperable con otro hardware o software que los
								mencionados en el artículo 1.
							</li>
							<li>
								distribuir, dar, vender, sub-licenciar o transferir de otro
								modo, incluso gratis, todo o parte de los derechos conferidos
								por el presente Contrato, por cualquier medio, a cualquier
								persona; como tal, el Usuario se compromete a borrar su
								Aplicación YONDR de su Terminal en el caso que transferiría la
								propiedad o el control a un tercero; y
							</li>
							<li>
								eliminar, ocultar o alterar cualquier aviso de propiedad o marca
								fijada en los créditos de la Aplicación YONDR.
							</li>
						</ul>
					</p>
					<h2>Artículo 7 - Garantías y Responsabilidades</h2>
					<p>
						La Compañía hará todo lo posible para permitir un acceso continuo a
						la Aplicación YONDR y proporcionar información precisa, con
						diligencia y de acuerdo al estado del arte, a excepción de
						interrupción requerido bajo mantenimiento, posibles averías,
						restricciones técnicas o jurídicas.
					</p>
					<p>
						La Aplicación YONDR surge de un área particularmente compleja de la
						tecnología informática y en el estado actual de los conocimientos, y
						no puede ser sujeto a pruebas de utilización en todos las
						circunstancias posibles de utilización y, en la medida permitida por
						ley aplicable de obligado cumplimiento, ninguna otra garantía que
						las descritas en estas Condiciones Generales se puede otorgar al
						Usuario. En particular, no se puede garantizar la adecuación de la
						Aplicación YONDR a todas las necesidades y exigencias del Usuario
						bajo su propio criterio o el funcionamiento continuo de la
						Aplicación YONDR sin errores o daños. La Aplicación YONDR Y LOS
						SERVICIOS SE PROPORCIONAN “TAL CUAL”, como parte de obligación de
						medios.
					</p>
					<p>
						El Usuario reconoce que los Servicios y actividades de La Compañía,
						que no es un profesional de la salud, no entran en ningún caso como
						consejo, asesoramiento, diagnóstico o receta médicos y que los
						Servicios son únicamente programas de ejercicios y de nutrición
						generales que no constituyen, y no son destinados a constituir, y no
						ser considerados como consejos o recetas médicos. Ninguno de los
						Servicios puede ser considerado como consejo médico, un diagnostico
						o tratamiento o receta médico. El Usuario deberá en todo caso
						consultar su médico o un profesional de salud con respecto a si
						estado de salud.
					</p>
					<p>
						Los Servicios son directrices sobre cómo realizar ciertos ejercicios
						y/o crear menús dietéticos o para realizar algunos programas, pero
						se recomienda al Usuario consultar varias fuentes de información,
						consultar a su médico antes de una actividad deportiva y adaptar los
						ejercicios y programas a su estado de salud. Ud. acepta que La
						Compañía no será responsable de ninguna lesión o enfermedad física o
						mental que pueda resultar, directa o indirectamente.
					</p>
					<p>
						La Compañía asume la obligación de medios en la ejecución del
						Contrato y no se hace responsable de los defectos en el
						funcionamiento de la Aplicación YONDR por el mero hecho de su
						existencia, ni de los resultados que se puedan conseguir a través
						del uso de la Aplicación YONDR.
					</p>
					<p>
						La asistencia, el mantenimiento y la actualización de la Aplicación
						YONDR en beneficio del Usuario no están incluidos en la licencia
						prevista en estas Condiciones Generales. Por ello, el Usuario
						reconoce que La Compañía no tiene ninguna obligación de proporcionar
						servicios de mantenimiento y soporte para la Aplicación YONDR.
					</p>
					<p>
						La Compañía no es responsable de los contenidos de terceros
						posiblemente accesibles mediante la Aplicación YONDR, de su
						exactitud, legalidad, calidad o su carácter decente y en términos
						generales no puede garantizar los contenidos y servicios por
						terceros vía la Aplicación YONDR. Tales contenidos o servicios se
						rigen por las disposiciones y condiciones aplicables, a los cuales
						no es parte La Compañía. Los hipervínculos a otros sitios web son
						orientativos pero podrán evolucionar según la voluntad de estos
						terceros.
					</p>
					<p>
						En la máxima medida permitida por ley aplicable de obligado
						cumplimiento, La Compañía no se hace responsable de cualquier daño
						que tenga su origen en la utilización de la Aplicación YONDR que no
						cumplen con estas Condiciones Generales, los consejos de los propios
						y eventuales médicos o profesionales de la salud del Usuario, ni de
						cualquier problema técnico del Usuario en su Terminal. La Compañía
						no se hace responsable si por cualquier razón fuera de su control,
						la Aplicación YONDR es inaccesible o de mala calidad, en particular
						debido al Usuario, su Terminal o los medios para acceder y utilizar
						la Aplicación YONDR.
					</p>
					<p>
						El Usuario es el único responsable de la utilización que hace de los
						Servicios y de las consecuencias nocivas de los mismos, frente a La
						Compañía o terceros, en particular en caso de incumplimiento de sus
						obligaciones previstas en estas Condiciones Generales.
					</p>
					<p>
						La responsabilidad de cada parte se establecerá únicamente en caso
						de daño previsible y emergente, que resulta de un fallo grave
						demostrado de sus obligaciones que les es directamente imputable.
					</p>
					<p>
						El operador de la Tienda Online no tiene ninguna obligación de
						garantía respecto de la Aplicación YONDR y cualesquiera otras
						reclamaciones, daños, pérdidas, responsabilidades o gastos
						resultantes de un defecto de Aplicación YONDR. Ninguna reclamación
						será ejecutable contra él, incluyendo (i) las reclamaciones debidas
						al producto, (ii) las reclamaciones relativas a la violación de la
						Aplicación YONDR de los requisitos legales o reglamentarias, (iii)
						las reclamaciones relativas a la protección del consumidor o la
						confidencialidad y (iv) las reclamaciones relacionadas a la
						infracción de los derechos de propiedad intelectual de un tercero.
					</p>
					<p>
						<b>
							EN LA MÁXIMA MEDIDA PERMITIDA POR LEY APLICABLE DE OBLIGADO
							CUMPLIMIENTO, LAS LIMITACIONES Y EXCLUSIONES DE GARANTÍA Y DE
							RESPONSABILIDAD PREVISTAS EN ESTAS CONDICIONES GENERALES SE
							APLICAN EN LA MAYOR MEDIDA POSIBLE SEA CUAL SEA EL FUNDAMENTO DE
							LA RESPONSABILIDAD.
						</b>
					</p>
					<p>
						Si la Aplicación YONDR no cumple con cualquier garantía aplicable,
						el Usuario podrá enviar una notificación a La Compañía.
					</p>
					<h2>Artículo 8 - Duración y Rescisión</h2>
					<p>
						El Usuario puede en cualquier momento dejar de utilizar la
						Aplicación YONDR, eliminando la Aplicación YONDR de su Terminal.
					</p>
					<p>
						La Compañía se reserva el derecho de modificar, suspender o terminar
						el Servicio y/o la Aplicación YONDR, bien de modo completo o bien de
						cualquiera de sus partes integrantes. Los Usuarios que utilicen la
						Aplicación YONDR lo hacen con pleno conocimiento y aceptación de
						dicha facultad por parte de La Compañía respecto a contrataciones,
						comprometiéndose en consecuencia a no ejercer contra La Compañía
						acción alguna como consecuencia del empleo por su parte de tal
						facultad.
					</p>
					<h2>Artículo 9 – Política de Confidencialidad y Privacidad</h2>
					<p>
						<b>9.1. Los datos personales: </b> la creación de una Cuenta de
						Usuario y la prestación de los Servicios requieren la recogida por
						parte de La Compañía de una cierta cantidad de datos personales
						relativos al Usuario, como las descritas en el artículo 3.
					</p>
					<p>
						Los datos recogidos son necesarios para el cumplimiento de sus
						obligaciones por parte de La Compañía, especialmente por la
						prestación de los Servicios y el ejercicio de los derechos previstos
						en estas Condiciones Generales. La Compañía también utiliza los
						datos personales para crear una base de datos de clientes. El
						Usuario que no desea proporcionar estas informaciones no puede
						utilizar la Aplicación YONDR ni recibir los Servicios.
					</p>
					<p>
						El responsable del tratamiento de estos datos es La Compañía cuyos
						detalles se proporcionan al final de las Condiciones Generales.
					</p>
					<p>
						Estos datos personales se mantienen por La Compañía (o su
						subcontratista), como proveedor de Servicios a lo largo del periodo
						de uso del Usuario en condiciones de seguridad, de acuerdo con las
						medidas actuales que ofrece la técnica. La Compañía se compromete de
						no utilizarlos en otro contexto ni comunicarlos a terceros. La
						Compañía permite el acceso a los datos a sus proveedores de sus
						servicios técnicos (hosting y servicios de correo electrónico,
						mailing) sólo con el consentimiento del Usuario, que otorga en este
						acta, o en casos previstos por la ley.
					</p>
					<p>
						<b>
							EL USUARIO CONSIENTE EXPRESAMENTE LA TRANSFERENCIA DE SUS DATOS
							PERSONALES EN LOS SERVIDORES.
						</b>
					</p>
					<p>
						Los datos de contacto de los Usuarios se almacenan en los servidores
						de La Compañía en condiciones de seguridad, de acuerdo con las
						medidas actuales que ofrece la técnica.
					</p>
					<p>
						De acuerdo con la ley, el Usuario dispone del derecho de acceso,
						rectificación, corrección de sus datos proporcionados, y a la
						oposición a su tratamiento. Para ello, será suficiente hacer una
						solicitud a La Compañía, mandando un correo electrónico o por correo
						a la dirección indicada al final de los Términos y Condiciones,
						agregando una fotocopia de su documento de identidad o pasaporte
						para dar evidencia de su identidad.
					</p>
					<p>
						Al finalizar la prestación de los Servicios, los datos se
						conservarán bloqueados durante los plazos legalmente establecidos, a
						las únicas finalidades de defender los intereses legítimos de La
						Compañía. Luego serán eliminados definitivamente.
					</p>
					<p>Para más detalles, consultad nuestra política de privacidad.</p>
					<h2>Artículo 10 – Disposiciones Generales</h2>
					<p>
						<b>10.1. Renuncia: </b>El hecho que una Parte renuncie a exigir en
						un momento dado el estricto cumplimiento por la otra Parte de
						cualquier disposición o condición del presente Contrato no será
						considerado une renuncia definitiva a dicha disposición o condición.
					</p>
					<p>
						<b>10.2. Nulidad de una cláusula: </b>Si una cláusula no esencial
						del Contrato se considera nula o inaplicable en virtud de una ley o
						de un reglamento o tras una decisión aplicable de una autoridad
						competente, las Partes convienen expresamente que el Contrato no se
						verá afectado por la nulidad de la cláusula en cuestión.
					</p>
					<p>
						<b>10.3. Leyes aplicables y jurisdicción: </b>Este Contrato con
						Usuarios con domicilio en España se rige por la ley española.
					</p>
					<p>
						En caso de desacuerdo o conflicto entre La Compañía y el Usuario en
						relación con la validez, el cumplimiento o incumplimiento o
						interpretación de los presentes o más en general, las relaciones
						entre La Compañía y el Usuario, las partes podrán recurrir a la
						mediación o cualquier otro modo alternativo de solución de
						conflictos competente establecido para ello en el territorio del
						domicilio del Usuario.
					</p>
					<p>
						<b>
							SE HACE CONSTAR EXPRESAMENTE QUE LA SOLICITUD DE UN ACUERDO
							AMISTOSO NO SUSPENDE LOS PLAZOS ABIERTOS PARA EMPRENDER ACCIONES
							LEGALES.
						</b>
					</p>
					<p>
						A falta de solución amistosa, cualquier disputa se someterá a la
						jurisdicción competente de acuerdo con el Código de Procedimiento
						Civil, y el Usuario podrá iniciar acciones en los tribunales
						competentes de su domicilio.
					</p>
					<h2>Artículo 11 - Contacto</h2>
					<p>
						Yondr S.L. es una empresa española inscrita en Cartagena, con
						domicilio en Calle Pintor Balaca 7, 30205, Murcia, España.
					</p>
					<p>
						El Usuario podrá contactar La Compañía por correo electrónico en la
						dirección hola@yondr.es.
					</p>
				</div>
			</section>
		</>
	)
}

export default termsScreen
