import React, { useState } from 'react'

// UI/UX
import './downloadStrip.scss'

//Components
// import appleVidrio from "../../resources/logos/apple-vidrio.svg";
import { AppleLogo, AndroidLogo } from '../../resources/Logos'

const DownloadStrip = () => {
	return (
		<>
			<ul className='download-strip-flex-list' id='store-buttons'>
				<li className='list-item' id='iOS'>
					<a
						href='itms-apps://apple.com/app/id1536111702'
						className='btn--icon-rounded'
					>
						<AppleLogo />
					</a>
				</li>
				<li className='list-item' id='Android'>
					<a
						href='https://play.google.com/store/apps/details?id=yondr.es.android.prod'
						className='btn--icon-rounded'
					>
						<AndroidLogo />
					</a>
				</li>
			</ul>
		</>
	)
}

export default DownloadStrip
