// General
import React, { useState, useEffect } from 'react'

// Routing
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

//UI
import './shareScreen.scss'
import { YondrLogoTypo } from '../../resources/Logos'
import { YondrAppIcon } from '../../resources/Icons'

import DownloadStrip from '../../components/DownloadStrip/DownloadStrip'

import { Helmet } from 'react-helmet'

export default function ShareScreen() {
	const { search } = useLocation()
	const { type, id, store } = queryString.parse(search)
	const [contentType, setContentType] = useState('')
	const [contentId, setContentId] = useState('')
	const [operativeSystem, setOperativeSystem] = useState('')
	const [targetUrl, setTargetUrl] = useState('')
	const [isEmpty, setIsEmpty] = useState(true)

	useEffect(() => {
		setTargetUrl(window.location.href + '&store=true')

		if (type && id) {
			setIsEmpty(false)
			switch (type) {
				case 'workouts':
					setContentType('Entreno')
					break
				case 'training-plans':
					setContentType('Plan')
					break
				case 'exercises':
					setContentType('Ejercicio')
					break
				case 'posts':
					setContentType('Post')
					break
				default:
					setIsEmpty(true)
					break
			}
		}
	})

	const handleOpen = () => {
		var sharedUrl = window.location.href
			.toString()
			.split(window.location.host)[1]

		var urlToYondrDeepLink = 'yondr:/' + sharedUrl

		window.location = urlToYondrDeepLink

		var operativeSystem = getMobileOperatingSystem()
		setOperativeSystem(operativeSystem)

		switch (operativeSystem) {
			case 'iOS':
				var urlToiOSApp = 'yondr:/' + sharedUrl
				setTimeout(function () {
					window.location = window.location.href + '&store=true'
				}, 500)
				window.location = urlToiOSApp
				break
			case 'Android':
				var urlToAndroidApp = 'yondr://' + sharedUrl
				setTimeout(function () {
					window.location = window.location.href + '&store=true'
				}, 500)
				window.location = urlToAndroidApp
				break

			default:
				//Show modal explaining the user to go to App Store or Play Store
				alert('Abre este enlace desde un dispositivo móvil iOS')
		}
	}

	const getMobileOperatingSystem = () => {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone'
		}

		if (/android/i.test(userAgent)) {
			return 'Android'
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS'
		}

		return 'unknown'
	}

	return (
		<>
			{isEmpty ? (
				<>
					<Helmet>
						<meta charSet='utf-8' />
						<title>Ups - YONDR</title>
					</Helmet>
					<section className='section' id='content-shared'>
						<div className='container'>
							<YondrLogoTypo className='yondr-logo-typo --large --mobile' />
							<h1>Ups!</h1>
							<h2>Parece que hay un problema con el enlace</h2>
						</div>
					</section>
				</>
			) : (
				<>
					<Helmet>
						<meta charSet='utf-8' />
						<title>{contentType} compartido - YONDR</title>
					</Helmet>
					<section className='section' id='content-shared'>
						<div className='container'>
							<YondrLogoTypo className='yondr-logo-typo --large --mobile' />
							<h1>ÁBRELO EN LA APP</h1>
							<h2>{contentType} compartido</h2>
							<a onClick={() => handleOpen()}>
								<YondrAppIcon className='yondr-app-icon --large' id='pic' />
							</a>
							<button
								className='btn--simple --medium --center'
								onClick={() => handleOpen()}
							>
								Abrir
							</button>
						</div>
					</section>
					<section className='section' id='stores-strip'>
						<div className='container'>
							<p>¿Aún no estás en YONDR? Descarga la app!</p>
							<DownloadStrip />
						</div>
					</section>
				</>
			)}
		</>
	)
}
