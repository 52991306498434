// General
import React, { Component } from 'react'

//UI
import './wipScreen.scss'

class WipScreen extends Component {
	constructor(props) {
		super(props)
	}
	render() {
		return <div className='full'>Estamos trabajando en ello!</div>
	}
}

export default WipScreen
