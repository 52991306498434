import React, { useState } from 'react'

// UI/UX
import './socialMediaStrip.scss'

//Components
import {
	InstagramLogo,
	FacebookLogo,
	TwitterLogo,
	YoutubeLogo,
	TwitchLogo,
} from '../../resources/Logos'

const SocialMediaStrip = () => {
	return (
		<>
			<ul className='round-buttons-list' id='social-media-buttons'>
				<li className='list-item' id='instagram'>
					<a
						href='https://www.instagram.com/yondr_es/'
						className='btn--icon-rounded --small'
					>
						<InstagramLogo />
					</a>
				</li>
				<li className='list-item' id='facebook'>
					<a
						href='https://www.facebook.com/YONDResp'
						className='btn--icon-rounded --small'
					>
						<FacebookLogo />
					</a>
				</li>
				{/* <li className="list-item" id="twitter">
          <a href="" className="btn--icon-rounded --small">
            <TwitterLogo />
          </a>
        </li> */}
				{/* <li className="list-item" id="youtube">
          <a href="" className="btn--icon-rounded --small">
            <YoutubeLogo />
          </a>
        </li> */}
				{/* <li className="list-item" id="twitch">
          <a href="" className="btn--icon-rounded --small">
            <TwitchLogo />
          </a>
        </li> */}
			</ul>
		</>
	)
}

export default SocialMediaStrip
