import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

// UI/UX
import './navBar.scss'

//Components
import { MainButton } from '../Buttons/MainButton/MainButton'
import SignUpModal from '../SignUpModal/SignUpModal'

import { YondrLogoPref, YondrLogoSymbol } from '../../resources/Logos'

export default function NavBar() {
	const [isClicked, setIsClicked] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [navBarScrolled, setNavbarScrolled] = useState(false)
	const [show, setShow] = useState(true)
	const [y, setY] = useState(window.scrollY)

	const handleNavigation = useCallback(
		(e) => {
			const window = e.currentTarget
			const currentY = window.scrollY
			const header = document.getElementById('header')

			if (y > currentY || y < 0) {
				if (currentY > 0) {
					// No está en el top
					// console.log("scrolling UP");
					header.classList.remove('hidden')
					header.classList.add('scrolling-up')
				} else {
					// Está en el top
					// console.log("In top");
					header.classList.remove('hidden')
					header.classList.remove('scrolling-up')
				}
			} else if (y < currentY) {
				// console.log("scrolling DOWN");
				header.classList.add('hidden')
			}
			setY(currentY)
		},
		[y]
	)

	const handleClick = () => {
		setIsClicked(!isClicked)
	}

	const handleOpenModal = () => {
		setIsModalOpen(!isModalOpen)
	}

	useEffect(() => {
		//Event for the header visibility handling
		setY(window.scrollY)
		window.addEventListener('scroll', handleNavigation)

		return () => {
			window.removeEventListener('scroll', handleNavigation)
		}
	}, [handleNavigation])

	return (
		<header className={isClicked ? 'header active' : 'header'} id='header'>
			<div className='header-wrapper'>
				<div className='brand-logo'>
					<a href='/'>
						<YondrLogoPref className='yondr-logo-pref' />
					</a>
				</div>
				<div className='brand-logo --mobile-visible'>
					<a href='/'>
						<YondrLogoSymbol className='yondr-logo-symbol --small' />
					</a>
				</div>
				<nav className='nav' id='main-nav-list'>
					<ul className='nav-list nav-list-mobile'>
						<li className='nav-item'>
							<div
								onClick={handleClick}
								className={isClicked ? 'mobile-menu active' : 'mobile-menu'}
							>
								<span className='line --line-top'></span>
								<span className='line --line-bottom'></span>
							</div>
						</li>
					</ul>
					<ul className='nav-list nav-list-larger' id='yondr-sections'>
						<li className='nav-item'>
							<a href='/tutorials' className='btn--simple --large'>
								DESCUBRE
							</a>
						</li>
						<li className='nav-item'>
							<a href='/faq' className='btn--simple --large'>
								FAQ
							</a>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	)
}
