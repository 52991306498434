// General
import React from 'react'

// Routing
import { Routes, Route } from 'react-router-dom'

// Screens
import ShareScreen from '../screens/shared-content/ShareScreen'
import LandingScreen from '../screens/landing/LandingScreen'
import TutorialsScreen from '../screens/tutorials/TutorialsScreen'
import TermsScreen from '../screens/terms-and-conditions/TermsScreen'
import WipScreen from '../screens/work-in-progress/WipScreen'
import FAQScreen from '../screens/faq/FAQScreen'
import WorkWithUsScreen from '../screens/work-with-us/WorkWithUsScreen'
import YondrScreen from '../screens/our-company/YondrScreen'
import ContactScreen from '../screens/contact/ContactScreen'
import NotFoundScreen from '../screens/404-not found/NotFoundScreen'
import AccountActivationScreen from '../screens/account/activation/AccountActivationScreen'
import AccountMailChangeScreen from '../screens/account/mail-change/AccountMailChangeScreen'
import AccountPasswordReset from '../screens/account/password-reset/AccountPasswordResetScreen'
import AccountPasswordResetConfirmation from '../screens/account/password-reset/AccountPasswordResetConfirmationScreen'
import DownloadScreen from '../screens/download/DownloadScreen'

export const RootRoutes = () => {
	return (
		<Routes>
			<Route exact path='/' element={<LandingScreen />} />
			<Route exact path='/faq' element={<FAQScreen />} />
			<Route exact path='/tutorials' element={<TutorialsScreen />} />
			<Route exact path='/terms' element={<TermsScreen />} />
			<Route exact path='/wip' element={<WipScreen />} />
			<Route exact path='/share' element={<ShareScreen />} />
			<Route exact path='/work-with-us' element={<WorkWithUsScreen />} />
			<Route exact path='/contact' element={<ContactScreen />} />
			<Route exact path='/yondr' element={<YondrScreen />} />
			<Route exact path='/download' element={<DownloadScreen />} />
			<Route
				exact
				path='/account/activation'
				element={<AccountActivationScreen />}
			/>
			<Route
				exact
				path='/account/password-reset'
				element={<AccountPasswordReset />}
			/>
			<Route
				path='/account/password-reset/confirmation'
				element={<AccountPasswordResetConfirmation />}
			/>
			<Route
				exact
				path='/account/mail-change'
				element={<AccountMailChangeScreen />}
			/>
			<Route path='' element={<NotFoundScreen />} />
		</Routes>
	)
}
