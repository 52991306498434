// General
import React, { Component } from 'react'
// UI/UX
import './formInput.scss'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

// Constants
const INPUT_FONT_COLOR = '#2D3241' //CARBONO
const LABEL_FONT_COLOR = '#888F99' //ALUMINIO
const LABEL_FONT_COLOR_FOCUSED = '#50555F' //ACERO
const INPUT_UNDERLINE_COLOR_FOCUSED = '#888F99' //ALUMINIO

const styles = (theme) => ({
	root: {
		width: '100%',

		'& label.Mui-focused': {
			color: LABEL_FONT_COLOR_FOCUSED,
		},
	},
	input: {
		fontFamily: 'wotfardsemibold',
		color: INPUT_FONT_COLOR,
		fontSize: '1rem',
		borderBottom: '1px solid #888F9930',
	},
	label: {
		fontFamily: 'wotfardmedium',
		color: LABEL_FONT_COLOR,
		fontSize: '1rem',
	},
	// helperText: {
	//   width: TEXTFIELD_WIDTH,
	//   paddingLeft: 10,
	//   fontSize: "10px",
	//   fontWeight: "bold",
	//   color: TEXTFIELD_ERRORLABEL_FONT_COLOR,
	//   textAlign: "left"
	// },
	// menu: {
	//   width: 200
	// }
})

class FormInput extends Component {
	constructor(props) {
		super(props)
		this.state = { data: [] }
	}

	render() {
		const {
			classes,
			onChange,
			name,
			label,
			type,
			variant,
			value,
			disabled,
			required,
			helperText,
			submitPressed,
			valid,
		} = this.props

		return (
			<div>
				<TextField
					className={classes.root}
					id='standard-required'
					name={name}
					label={label}
					type={type}
					variant={variant}
					value={value}
					required={required}
					disabled={disabled}
					margin='normal'
					onChange={onChange}
					autoComplete='new-password'
					InputProps={{
						className: classes.input,
						disableUnderline: true,
					}}
					InputLabelProps={{
						className: classes.label,
					}}
					helperText={helperText}
				>
					{''}
				</TextField>
				{/* <br />
        {!valid && submitPressed && (
          <label className={classes.helperText}>{helperText}</label>
        )} */}
			</div>
		)
	}
}

export default FormInput
