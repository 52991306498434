import React, { useState } from "react";

// UI/UX
import "./scrollFlag.scss";

const ScrollFlag = ({ id }) => {
  return (
    <>
      <div className="scroll-fly-container" id={id}>
        <div className="typo">DESLIZA</div>
        <div className="circle">
          <div className="outer-circle">
            <div className="sphere" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollFlag;
