import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './AccountActivationScreen.scss'
import { BASE_URL } from '../../../ApiUrls'

async function activateAccount(key) {
	// Default options are marked with *
	const response = await axios.post(
		BASE_URL + '/users/activation-confirmation',
		{ key: key }
	)
	return response
}

function AccountActivationScreen() {
	const [isDataFetched, setIsDataFetched] = useState(false)
	const [isActivated, setIsActivated] = useState(false)

	useEffect(() => {
		function getQueryVariable(variable) {
			var query = window.location.search.substring(1)
			var vars = query.split('&')
			for (var i = 0; i < vars.length; i++) {
				var pair = vars[i].split('=')
				if (pair[0] === variable) {
					return pair[1]
				}
			}
			return false
		}
		activateAccount(getQueryVariable('key'))
			.then(() => {
				// console.log('ESTÁ BIEEEN')
				setIsActivated(true)
			})
			.catch(() => {
				setIsActivated(false)
			})
			.finally(() => setIsDataFetched(true))
	}, [])

	return (
		<div className='container' id='activation-container'>
			<div className='wrapper'>
				{isDataFetched && (
					<>
						{isActivated ? (
							<div id='confirm'>
								<h2 className='subtitle'>
									Hemos activado tu cuenta! Disfruta de la mayor plataforma de
									fitness sin límites.
								</h2>
							</div>
						) : (
							<div id='wrong'>
								<h2 className='subtitle'>
									Ups...parece que ha habido un error en el proceso. Si se
									repite ponte en contacto con{' '}
									<a className='support-link' href='/contact'>
										soporte
									</a>
									.
								</h2>
							</div>
						)}
						<div className='signature'>Equipo YONDR</div>
					</>
				)}
			</div>
		</div>
	)
}

export default AccountActivationScreen
