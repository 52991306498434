// General
import React, { useState, useEffect, useCallback, useRef } from 'react'
import ReactDOM, { createPortal } from 'react-dom'

// UI/UX
import './videoPlayer.scss'

//Components
import CloseIcon from '@mui/icons-material//Close'
import loadingGif from '../../resources/images/Loading-gif.gif'

const VideoPlayer = ({ video, showVideoPlayer, setShowVideoPlayer }) => {
	const videoRef = useRef()

	const dismiss = () => {
		var video = document.getElementById('video')
		video.pause()
		video.currentTime = 0
		setShowVideoPlayer(false)
	}

	const closeVideoPlayer = (e) => {
		if (videoRef.current === e.target) {
			setShowVideoPlayer(false)
		}
	}

	const keyPress = useCallback(
		(e) => {
			if (e.key === 'Escape' && showVideoPlayer) {
				setShowVideoPlayer(false)
			}
		},
		[setShowVideoPlayer, showVideoPlayer]
	)

	useEffect(() => {
		document.addEventListener('keydown', keyPress)
		return () => document.removeEventListener('keydown', keyPress)
	}, [keyPress])

	return (
		<>
			{showVideoPlayer ? (
				<div
					className='video-player-container'
					id='video-player-container'
					ref={videoRef}
					onClick={closeVideoPlayer}
				>
					<div className='close-btn-wrapper'>
						<CloseIcon className='close-btn' onClick={() => dismiss()} />
					</div>
					<div class='video-player' id='video-player'>
						<video
							className='video'
							id='video'
							poster={loadingGif}
							preload='auto'
							controls
							autoPlay
						>
							<source src={video} />
						</video>
					</div>
				</div>
			) : null}
		</>
	)
}

export default VideoPlayer
