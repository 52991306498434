// General
import React, { useState } from 'react'

//UI
import './landingScreen.scss'
// import ScrollMagic from 'scrollmagic'
// import '../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'
// import '../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import VideoPlayer from '../../components/Video player/VideoPlayer'
// import gsap from 'gsap'
// Components
import { YondrLogoTypo } from '../../resources/Logos'

import FollowersImage from './media/Followers.png'
import FollowImage from './media/Follow.png'
import CreateImage from './media/Create.png'
import SendInYondr from './media/Send_in_yondr.png'
import ShareInMessagingApps from './media/Share_in_messaging-apps.png'
import ShareInInstagram from './media/Share_in_instagram.png'

import DownloadStrip from '../../components/DownloadStrip/DownloadStrip'
import ScrollFlag from '../../components/ScrollFlag/ScrollFlag'
import workoutsStripImage from './media/Workouts carousel.png'

import planifyImage from './media/planify-pic.png'
import executeImage from './media/Execute-pic.png'

// import LandingVideo from "./PruebaPhoneContornos.webm";

//Videos
import LandingVideo from './media/App Preview v2.mp4'
import LandingVideoWithFrame from './media/App Preview v2.mp4'
import DemoSendingInApp from './media/DemoSendingInApp.m4v'
import DemoSendingInWhatsapp from './media/DemoSendingInWhatsapp.m4v'
import DemoSharingByInsta from './media/DemoSharingByInsta.m4v'
import DemoCreateYourWorkouts from './media/DemoCreateYourWorkouts.m4v'

function LandingScreen() {
	const [openVideo, setOpenVideo] = useState(false)
	const [video, setVideo] = useState({})

	const openVideoPlayer = (video) => {
		setVideo(video)
		setOpenVideo(true)
	}

	// useEffect(() => {
	// 	var controller = new ScrollMagic.Controller()

	// 	// WELCOME SECTION //
	// 	var welcomeDismissAnimation = gsap.timeline()
	// 	welcomeDismissAnimation
	// 		.to('#scroll-flag', {
	// 			duration: 1,
	// 			transform: 'translateY(-150px)',
	// 			opacity: 0,
	// 		})
	// 		.to(
	// 			'#welcome-caption',
	// 			{ duration: 1, transform: 'translateY(-150px)', opacity: 0 },
	// 			'-=1'
	// 		)
	// 		.to(
	// 			'#welcome-preview',
	// 			{ duration: 1, transform: 'translateY(-150px)', opacity: 0 },
	// 			'-=1'
	// 		)
	// 	var welcomeDismissAnimationScene = new ScrollMagic.Scene({
	// 		triggerElement: '#create',
	// 		triggerHook: 'onCenter',
	// 		duration: '20%',
	// 	})
	// 		.setTween(welcomeDismissAnimation)
	// 		// .addIndicators({ name: "- welcome dismiss" })
	// 		.addTo(controller)

	// 	// CREATE SECTION //
	// 	var createPresentAnimation = gsap.timeline()
	// 	createPresentAnimation
	// 		.set('#create-title', {
	// 			transform: 'translateY(100px)',
	// 			opacity: 0,
	// 		})
	// 		.set('#create-p', {
	// 			transform: 'translateY(100px)',
	// 			opacity: 0,
	// 		})
	// 		.set('#create-demo', {
	// 			transform: 'translateY(100px)',
	// 			opacity: 0,
	// 		})
	// 		.set('#create-caroussel', {
	// 			transform: 'translateY(100px)',
	// 			opacity: 0,
	// 		})
	// 		.to('#create-title', {
	// 			duration: 1,
	// 			transform: 'translateY(0px)',
	// 			opacity: 1,
	// 		})
	// 		.to('#create-p', {
	// 			duration: 1,
	// 			transform: 'translateY(0px)',
	// 			opacity: 1,
	// 		})
	// 		.to('#create-demo', {
	// 			duration: 1,
	// 			transform: 'translateY(0px)',
	// 			opacity: 1,
	// 		})
	// 		.to('#create-caroussel', {
	// 			duration: 1,
	// 			transform: 'translateY(0px)',
	// 			opacity: 1,
	// 		})
	// 	var createPresentAnimationScene = new ScrollMagic.Scene({
	// 		triggerElement: '#create',
	// 		triggerHook: 'onLeave',
	// 		duration: '20%',
	// 		offset: '-250px',
	// 	})
	// 		.setTween(createPresentAnimation)
	// 		// .addIndicators({ name: "- create present" })
	// 		.addTo(controller)

	// 	var carousselAnimation = gsap.timeline()
	// 	carousselAnimation.to('#create-caroussel', {
	// 		transform: 'translateX(-1500px)',
	// 	})
	// 	var carousselAnimation = new ScrollMagic.Scene({
	// 		triggerElement: '#create',
	// 		triggerHook: 'onLeave',
	// 		duration: '130%',
	// 	})
	// 		.setTween(carousselAnimation)
	// 		// .addIndicators({ name: "- carrussel animation" })
	// 		.addTo(controller)

	// 	// SHARE SECTION //
	// 	var sharePresentAnimation = gsap.timeline()
	// 	sharePresentAnimation
	// 		.set('#share-title', {
	// 			transform: 'translateY(100px)',
	// 			opacity: 0,
	// 		})
	// 		.set('#share-flex', {
	// 			transform: 'translateY(100px)',
	// 			gap: '10rem',
	// 			opacity: 0,
	// 		})
	// 		.to('#share-title', {
	// 			duration: 1,
	// 			transform: 'translateY(0px)',
	// 			opacity: 1,
	// 		})
	// 		.to('#share-flex', {
	// 			duration: 1,
	// 			transform: 'translateY(0px)',
	// 			opacity: 1,
	// 			gap: '1.5rem',
	// 		})
	// 	var sharePresentAnimationScene = new ScrollMagic.Scene({
	// 		triggerElement: '#share',
	// 		triggerHook: 'onCenter',
	// 		duration: '50%',
	// 		offset: '-0',
	// 	})
	// 		.setTween(sharePresentAnimation)
	// 		// .addIndicators({ name: "- share present" })
	// 		.addTo(controller)

	// 	// PLANIFY SECTION //
	// 	var planifyPresentAnimation = gsap.timeline()
	// 	planifyPresentAnimation
	// 		.set('#planify-pic', {
	// 			transform: 'translateX(-150px)',
	// 			opacity: 0,
	// 		})
	// 		.set('#planify-caption', {
	// 			transform: 'translateX(150px)',
	// 			opacity: 0,
	// 		})
	// 		.to('#planify-pic', {
	// 			duration: 1,
	// 			transform: 'translateX(0px)',
	// 			opacity: 1,
	// 		})
	// 		.to(
	// 			'#planify-caption',
	// 			{ duration: 1, transform: 'translateX(0px)', opacity: 1 },
	// 			'-=1'
	// 		)
	// 	var planifyPresentAnimationScene = new ScrollMagic.Scene({
	// 		triggerElement: '#planify',
	// 		triggerHook: 'onCenter',
	// 		duration: '50%',
	// 		offset: '0',
	// 	})
	// 		.setTween(planifyPresentAnimation)
	// 		// .addIndicators({ name: "- planify present" })
	// 		.addTo(controller)

	// 	// INTERSECTION SECTION //
	// 	var intersectionPresentAnimation = gsap.timeline()
	// 	intersectionPresentAnimation
	// 		.set('#bicolor-text', {
	// 			'letter-spacing': '3rem',
	// 			opacity: 0,
	// 		})
	// 		.to('#bicolor-text', {
	// 			duration: 1,
	// 			'letter-spacing': function () {
	// 				var w = window.innerWidth

	// 				if (w < 428) {
	// 					return '0.4rem'
	// 				} else {
	// 					return '1rem'
	// 				}
	// 			},
	// 			opacity: 1,
	// 		})

	// 	var intersectionPresentAnimationScene = new ScrollMagic.Scene({
	// 		triggerElement: '#intersection',
	// 		triggerHook: 'onEnter',
	// 		duration: '50%',
	// 		// offset: "-300px"
	// 	})
	// 		.setTween(intersectionPresentAnimation)
	// 		// .addIndicators({ name: "- planify present" })
	// 		.addTo(controller)
	// }, [])

	return (
		<div>
			<VideoPlayer
				video={video}
				showVideoPlayer={openVideo}
				setShowVideoPlayer={setOpenVideo}
			/>
			<section className='section' id='welcome'>
				<div className='container'>
					<div className='caption' id='welcome-caption'>
						<YondrLogoTypo className='yondr-logo-typo --mobile' />
						<h1>BIENVENIDOS AL FITNESS SOCIAL</h1>
						<h2>Descubre, entrena y comparte el fitness con amigos</h2>
						<div className='download-block'>
							!Descárgala y únete!
							<DownloadStrip />
						</div>
					</div>
					<div className='preview' id='welcome-preview'>
						<div className='video-container'>
							{/* <PhoneFrame className="phone-frame" /> */}
							<video
								className='yondr-video'
								preload='true'
								autoPlay='autoPlay'
								loop
								muted
							>
								<source src={LandingVideo} type='video/mp4' />
							</video>
						</div>
						<div className='cta'>
							<button
								className='cta-link'
								onClick={(e) => openVideoPlayer(LandingVideoWithFrame)}
							>
								VER PREVIEW
								<PlayCircleOutlineIcon className='cta-icon-play' />
							</button>
						</div>
					</div>
					<ScrollFlag id='scroll-flag' />
					<div className='section-divider-ramp-up' />
					<div className='spacer' />
				</div>
			</section>
			<section className='section' id='discover'>
				<div className='container'>
					<h1 id='discover-title'>
						TU COMUNIDAD FITNESS. IN
						<span style={{ color: '#F7CA0C' }}>FIT</span>
						NITAS POSIBILIDADES.
					</h1>
					<p id='discover-p'>
						Descubre entrenos, ejercicios, planis...inreíbles de la comunidad.
					</p>
					<div className='spacer' />
					<img
						className='image-caroussel'
						id='discover-caroussel'
						src={workoutsStripImage}
					/>
					<div className='section-divider-down-arrow' />
				</div>
			</section>
			<section className='section' id='follow'>
				<img src={FollowersImage} id='followers-pic' />
				<div className='container'>
					<div id='caption-block'>
						<h1 id='follow-title'>
							SIGUE A TUS <span style={{ color: '#EBEBEB' }}>AMIGOS</span>
						</h1>
						<p>
							Comparte rutinas, planis con la comunidad y evolucionad juntos
						</p>
					</div>
					<img src={FollowImage} id='pic' />
				</div>
			</section>
			<section className='section' id='create'>
				<div className='container'>
					<h1 id='create-title'>
						¿DISEÑAR TUS ENTRENOS Y ORGANIZAR TUS PLANIS?<br></br> ASÍ DE FÁCIL
						;{`)`}
					</h1>
					{/* <p id='create-p'>
						Diseña entrenos y planes de manera sencilla y rápida.
					</p> */}
					<div className='cta' id='create-demo' style={{ color: '#ebebeb' }}>
						<button onClick={(e) => openVideoPlayer(DemoCreateYourWorkouts)}>
							Ver DEMO
							<PlayCircleOutlineIcon className='cta-icon-play' />
						</button>
					</div>
					<img
						id='create-pic'
						src={CreateImage}
						alt='yondr-design-and-create-fitness-content'
					/>
					<div className='section-divider-down-arrow' />
				</div>
			</section>
			<section className='section' id='share'>
				<div className='container'>
					<h1 id='share-title'>COMPARTE ENTRENOS Y PLANES EN</h1>
					<div className='flex-container' id='share-flex'>
						<div className='flex-item'>
							<h2> La app </h2>
							<div className='pic-container'>
								<img className='pic' src={SendInYondr} />
							</div>
							<p>Envíalos a tus amigos directamente a través de la app.</p>
							<div className='cta'>
								<button onClick={(e) => openVideoPlayer(DemoSendingInApp)}>
									Ver DEMO
									<PlayCircleOutlineIcon className='cta-icon-play' />
								</button>
							</div>
						</div>
						<div className='flex-item'>
							<h2> Apps de mensajería</h2>
							<div className='pic-container'>
								<img className='pic' src={ShareInMessagingApps} />
							</div>
							<p>
								Comparte entrenos y planes por Whatsapp, Telegram o cualquier
								otra app de mensajería.
							</p>
							<div className='cta'>
								<button onClick={(e) => openVideoPlayer(DemoSendingInWhatsapp)}>
									Ver DEMO
									<PlayCircleOutlineIcon className='cta-icon-play' />
								</button>
							</div>
						</div>
						<div className='flex-item'>
							<h2> Redes sociales </h2>
							<div className='pic-container'>
								<img className='pic' src={ShareInInstagram} />
							</div>
							<p>
								¿Eres influencer? Comparte el link en tus stories y tus
								seguidores podrán aprovechar tus entrenos y planes en YONDR.
							</p>
							<div className='cta'>
								<button onClick={(e) => openVideoPlayer(DemoSharingByInsta)}>
									Ver DEMO
									<PlayCircleOutlineIcon className='cta-icon-play' />
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='section' id='planify'>
				<div className='container'>
					<div className='section-divider-down-arrow --amarillo --absolute-top' />
					<div className='spacer' id='spacer1' />
					<img src={planifyImage} id='planify-pic' />
					<div className='caption' id='planify-caption'>
						<h1>PLANIFICA. SÓLO UNA VEZ.</h1>
						<p>
							Añade tu plan al calendario y olvídate. Cada día verás el
							entrenamiento que te toca.
						</p>
					</div>
					<div className='spacer' id='spacer2' />
				</div>
			</section>
			<section className='section' id='intersection'>
				<div className='intersect-container'>
					<div className='bicolor-intersect-wrapper'>
						<div className='bicolor-intersect' id='bicolor-text'>
							3 2 1 GO!
						</div>
					</div>
				</div>
			</section>
			<section className='section' id='execute'>
				<div className='container'>
					<div id='caption-block'>
						<h1>RÉTATE EN CADA ENTRENO</h1>
						<p>
							YONDR es tu compañero de entreno, ayudándote con cronómetros
							simples para que controles tus tiempos de descanso entre series
						</p>
					</div>
					<img src={executeImage} id='execute-pic' />
				</div>
			</section>
		</div>
	)
}

export default LandingScreen
