import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { BASE_URL } from '../../../ApiUrls'
import './AccountPasswordResetScreen.scss'

async function resetPassword(key, user, password) {
	const body = {
		key: key,
		user: user,
		newPassword: password,
	}
	// console.log(JSON.stringify(body))
	// Default options are marked with *
	const response = await axios.post(
		BASE_URL + '/users/reset-password-confirmation',
		body
	)
	return response
}

function getQueryVariable(variable) {
	var query = window.location.search.substring(1)
	var vars = query.split('&')
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=')
		if (pair[0] === variable) {
			return pair[1]
		}
	}
	return false
}

function AccountPasswordResetScreen() {
	const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [isError, setIsError] = useState(false)

	const [key, setKey] = useState('')
	const [user, setUser] = useState('')
	const [password, setPassword] = useState('')
	const [passwordLengthOk, setPasswordLengthOk] = useState(false)
	const [passwordVisible, setPasswordVisible] = useState(false)

	useEffect(() => {
		const key = getQueryVariable('key')
		const user = getQueryVariable('user')
		setKey(key)
		setUser(user)
	}, [])

	useEffect(() => {
		if (password.length >= 8) return setPasswordLengthOk(true)
		setPasswordLengthOk(false)
	}, [password])

	const handlePasswordChange = (e) => {
		if (e.nativeEvent.data === ' ') return
		setPassword(e.target.value)
	}

	const handlePasswordPaste = (e) => {
		const value = e.clipboardData.getData('Text')
		// console.log(value)
		const regex = /\s/
		const thereIsWhiteSpaces = regex.test(value)
		// console.log(thereIsWhiteSpaces)
		if (thereIsWhiteSpaces) return e.preventDefault()
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		setIsSubmitButtonClicked(true)

		if (!passwordLengthOk) return

		resetPassword(key, user, password)
			.then(() => {
				window.location.pathname = 'account/password-reset/confirmation'
			})
			.catch(() => {
				setIsError(true)
			})
			.finally(() => setIsSubmitted(true))
	}

	return (
		<div className='container' id='password-reset-container'>
			<div className='wrapper'>
				{isSubmitted ? (
					<>
						{isError && (
							<div id='wrong'>
								<h2 className='subtitle'>
									Ups...parece que ha habido un error en el proceso. Si se
									repite ponte en contacto con{' '}
									<a className='support-link' href='/contact'>
										soporte
									</a>
									.
								</h2>
							</div>
						)}
						<div className='signature'>Equipo YONDR</div>
					</>
				) : (
					<form className='form' onSubmit={handleSubmit} autoComplete='on'>
						<input type='hidden' name='key' id='key' value={key} />
						<input type='hidden' name='user' id='user' value={user} />
						<div className='password-wrapper'>
							<input
								name='newPassword'
								id='newPassword'
								className={'input-textfield'}
								type={passwordVisible ? '' : 'password'}
								value={password}
								onChange={handlePasswordChange}
								onPaste={handlePasswordPaste}
								placeholder='Escribe la nueva contraseña...'
								autoComplete='on'
							/>
							<div
								className='show-hide-button'
								onClick={() => setPasswordVisible(!passwordVisible)}
								id='toggle-button'
							>
								{passwordVisible ? 'OCULTAR' : 'MOSTRAR'}
							</div>
						</div>
						{!passwordLengthOk && isSubmitButtonClicked && (
							<p className='error-message'>
								La contraseña debe tener al menos 8 caracteres
							</p>
						)}

						<button
							className={
								password.trim().length === 0 ? 'button disabled' : 'button'
							}
							disabled={password.trim().length === 0}
							onClick={handleSubmit}
						>
							CAMBIAR CONTRASEÑA
						</button>
					</form>
				)}
			</div>
		</div>
	)
}

export default AccountPasswordResetScreen
