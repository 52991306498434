// General
import React, { useState } from 'react'

//UI
import './tutorialsScreen.scss'

import VideoPlayer from '../../components/Video player/VideoPlayer'

//Components
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'

//Media
import Tutorial1Pic from './media/1. Como crear tu cuenta en YONDR.jpg'
import Tutorial1Video from './media/1. Como crear tu cuenta en YONDR.m4v'
import Tutorial2Pic from './media/2. Tour por YONDR.jpg'
import Tutorial2Video from './media/2. Tour por YONDR.m4v'
import Tutorial3Pic from './media/3. Como crear un entreno.jpg'
import Tutorial3Video from './media/3. Como crear un entreno.m4v'
import Tutorial4Pic from './media/4. Como enviar entrenos a tus amigos.jpg'
import Tutorial4Video from './media/4. Como enviar entrenos a tus amigos.m4v'
import Tutorial5Pic from './media/5. Como compartir entrenos y planes.jpg'
import Tutorial5Video from './media/5. Como compartir entrenos y planes.m4v'
import Tutorial6Pic from './media/6. Como crear un plan.jpg'
import Tutorial6Video from './media/6. Como crear un plan.m4v'
import Tutorial7Pic from './media/7. Como añadir un plan al calendario.jpg'
import Tutorial7Video from './media/7. Como añadir un plan al calendario.m4v'
import Tutorial8Pic from './media/8. Como entrenar con YONDR.jpg'
import Tutorial8Video from './media/8. Como entrenar con YONDR.m4v'

function TutorialsScreen() {
	const [openVideo, setOpenVideo] = useState(false)
	const [video, setVideo] = useState({})

	const openVideoPlayer = (video) => {
		setVideo(video)
		setOpenVideo(true)
	}

	return (
		<>
			<VideoPlayer
				video={video}
				showVideoPlayer={openVideo}
				setShowVideoPlayer={setOpenVideo}
			/>
			<section className='section' id='tutorials-header'>
				<div className='container'>
					<h1>DESCUBRE QUÉ PUEDES HACER EN YONDR.</h1>
					<p>Echa un ojo a los tutoriales para sacarle el máximo partido.</p>
					<div className='section-divider-ramp-up' />
				</div>
			</section>
			<section className='section' id='tutorials-list'>
				<div className='container'>
					<ul className='tutorial-list'>
						<li
							className='list-item'
							onClick={(e) => openVideoPlayer(Tutorial1Video)}
						>
							<div className='tutorial-pic-container'>
								<div className='duration-bubble' id='duration-bubble'>
									0:30
								</div>
								<PlayCircleOutlineIcon className='play-icon' id='play-icon' />
								<img className='pic' src={Tutorial1Pic} />
							</div>
							<div className='description-container'>
								<h2>Cómo crear tu cuenta en YONDR</h2>
							</div>
						</li>
						<li
							className='list-item'
							onClick={(e) => openVideoPlayer(Tutorial2Video)}
						>
							<div className='tutorial-pic-container'>
								<div className='duration-bubble'>2:14</div>
								<PlayCircleOutlineIcon className='play-icon' />
								<img className='pic' src={Tutorial2Pic} />
							</div>
							<div className='description-container'>
								<h2>Tour por YONDR</h2>
							</div>
						</li>
						<li
							className='list-item'
							onClick={(e) => openVideoPlayer(Tutorial3Video)}
						>
							<div className='tutorial-pic-container'>
								<div className='duration-bubble'>3:23</div>
								<PlayCircleOutlineIcon className='play-icon' />
								<img className='pic' src={Tutorial3Pic} />
							</div>
							<div className='description-container'>
								<h2>Cómo crear un entreno</h2>
							</div>
						</li>
						<li
							className='list-item'
							onClick={(e) => openVideoPlayer(Tutorial4Video)}
						>
							<div className='tutorial-pic-container'>
								<div className='duration-bubble'>0:37</div>
								<PlayCircleOutlineIcon className='play-icon' />
								<img className='pic' src={Tutorial4Pic} />
							</div>
							<div className='description-container'>
								<h2>Cómo enviar entrenos a tus amigos</h2>
							</div>
						</li>
						<li
							className='list-item'
							onClick={(e) => openVideoPlayer(Tutorial5Video)}
						>
							<div className='tutorial-pic-container'>
								<div className='duration-bubble'>1:01</div>
								<PlayCircleOutlineIcon className='play-icon' />
								<img className='pic' src={Tutorial5Pic} />
							</div>
							<div className='description-container'>
								<h2>Cómo compartir entrenos y planes</h2>
							</div>
						</li>
						<li
							className='list-item'
							onClick={(e) => openVideoPlayer(Tutorial6Video)}
						>
							<div className='tutorial-pic-container'>
								<div className='duration-bubble'>2:03</div>
								<PlayCircleOutlineIcon className='play-icon' />
								<img className='pic' src={Tutorial6Pic} />
							</div>
							<div className='description-container'>
								<h2>Cómo crear un plan</h2>
							</div>
						</li>
						<li
							className='list-item'
							onClick={(e) => openVideoPlayer(Tutorial7Video)}
						>
							<div className='tutorial-pic-container'>
								<div className='duration-bubble'>0:52</div>
								<PlayCircleOutlineIcon className='play-icon' />
								<img className='pic' src={Tutorial7Pic} />
							</div>
							<div className='description-container'>
								<h2>Cómo añadir un plan al calendario</h2>
							</div>
						</li>
						<li
							className='list-item'
							onClick={(e) => openVideoPlayer(Tutorial8Video)}
						>
							<div className='tutorial-pic-container'>
								<div className='duration-bubble'>0:52</div>
								<PlayCircleOutlineIcon className='play-icon' />
								<img className='pic' src={Tutorial8Pic} />
							</div>
							<div className='description-container'>
								<h2>Cómo entrenar con YONDR</h2>
							</div>
						</li>
					</ul>
				</div>
			</section>
		</>
	)
}

export default TutorialsScreen
