import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

/* Firebase */
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
	apiKey: 'AIzaSyAkc6xTv3YF19n5_Yew04NnloUnjVEpdWA',
	authDomain: 'yondr-8af5c.firebaseapp.com',
	databaseURL: 'https://yondr-8af5c.firebaseio.com',
	projectId: 'yondr-8af5c',
	storageBucket: 'yondr-8af5c.appspot.com',
	messagingSenderId: '646694546001',
	appId: '1:646694546001:web:87c5fce5c76b4940512670',
	measurementId: 'G-ETWMX1KQQS',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// After
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
