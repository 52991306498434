// General
import React, { useState } from 'react'

// Routing
import { withRouter } from 'react-router-dom'

//UI
import './notFoundScreen.scss'

//Components
import { MailIcon } from '../../resources/Icons'

function NotFoundScreen() {
	return (
		<>
			<section className='section' id='not-found'>
				<div className='container'>
					<div className='hero-404'>404</div>
					<h1>Ups...</h1>
					<h2>Parece que la página que buscas no existe</h2>
				</div>
			</section>
		</>
	)
}

export default NotFoundScreen
