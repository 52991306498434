// General
import React, { useEffect } from 'react'

//UI
import './FAQScreen.scss'
import { PlusIcon } from '../../resources/Icons'

function FAQScreen() {
	const handleSendMessage = () => {
		sendMail()
	}

	const sendMail = () => {
		// var message = document.getElementById("user-text-area");
		var link =
			'mailto:hola@yondr.es' +
			'?cc=' +
			'&subject=' +
			encodeURIComponent('Hola YONDR quería comentarte que...') +
			'&body=' +
			encodeURIComponent('')
		window.location.href = link
	}

	useEffect(() => {
		var coll = document.getElementsByClassName('collapsible-block')
		var i

		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener('click', function () {
				this.classList.toggle('active')
			})
		}
	})

	return (
		<>
			<section className='section' id='faq-header'>
				<div className='container'>
					<h1>
						TUS DUDAS, <br /> RESUELTAS.
					</h1>
					<p>Revisa nuestro FAQ</p>
					<div className='section-divider-ramp-up' />
				</div>
			</section>
			<section className='section' id='faq-list'>
				<div className='container'>
					<div className='collapsible-block'>
						<div className='heading-wrapper'>
							<button type='button' class='collapsible-btn'>
								¿Qué es YONDR?
							</button>
							<PlusIcon className='plus-icon' />
						</div>
						<div class='content'>
							<p>
								YONDR es la primera plataforma social de Fitness, disponible
								para móviles y totalmente gratuita.
							</p>
							<p>
								En YONDR podrás descubrir contenido fitness de la comounidad
								(ejercicios, entrenos, planis...), compartirlo todo con amigos,
								clientes y/o seguidores... y planificarte para conseguir tus
								conseguir disfrutando del camino con tu propia comunidad
								fitness.
							</p>
						</div>
					</div>
					<div className='collapsible-block'>
						<div className='heading-wrapper'>
							<button type='button' class='collapsible-btn'>
								¿Es para mi?
							</button>
							<PlusIcon className='plus-icon' />
						</div>
						<div class='content'>
							<p>
								Si te gusta entrenar, comer sano y superarte...¡Sí, ésta es tu
								plataforma!
							</p>
							<p>
								Tanto si eres nuevo en el mundo del fitness como si eres un
								profesional, encontrarás en YONDR el ecosistema que te ayudará a
								conseguir tus objetivos, ya sea un hobby o te dediques a ello
								profesionalmente.
							</p>
						</div>
					</div>
					<div className='collapsible-block'>
						<div className='heading-wrapper'>
							<button type='button' class='collapsible-btn'>
								¿Qué me ofrece la plataforma?
							</button>
							<PlusIcon className='plus-icon' />
						</div>
						<div class='content'>
							<p>
								En YONDR dispones de un ecosistema donde construir tu estilo de
								vida fitness. En la plataforma puedes:
							</p>
							<ul className='bullet-list'>
								<li>
									<h3>DESCUBRE</h3>
									<p>
										En YONDR podrás ver contenido publicado por otros usuarios:
										ejercicios, entrenos, planificaciones...y podrás
										guardártelos para alimentar tu "Cuaderno FIT" y
										aprovecharlos cuando quieras. También podrás seguir a estos
										usuarios y que te sigan, para que no os perdáis el contenido
										increíble que publicáis para ayudar a la comunidad.
									</p>
								</li>
								<li>
									<h3>CREA</h3>
									<p>
										Podrás crear tu propio "Cuaderno FIT", donde diseñarás tus
										entrenamientos y tus planificaciones y donde se almacenarán
										también los que te guardes de la comunidad. Diseña tus
										entrenos, planificaciones e, incluso, ejercicios, de manera
										sencilla pero flexible y 100% personalizados.
									</p>
									<p>
										Dispones de una base de datos de ejercicios pero también
										puedes crear los tuyos propios, ya sea porque no encuentras
										el ejercicio que buscas o porque quieres que tus ejercicios
										sean personalizados para ti, tus amigos, seguidores o
										clientes.
									</p>
									<p>
										Puedes construir entrenamientos tan simples o complejos como
										necesites: ejercicios en superserie, circuitos, sub-series
										(drop-sets, clusters, rest-pause, etc).
									</p>
									<p>
										Dispondrás tanto de los entrenamientos que diseñes como los
										de tus amigos o la comunidad para construir planes
										increíbles.
									</p>
									<p>
										Como ves, las posibilidades son infintas. Todo de manera muy
										simple e intuitiva.
									</p>
								</li>
								<li>
									<h3>COMPARTIR</h3>
									<p>
										Compartir es vivir. Por eso en YONDR creemos que lo que
										sirve a un miembro de la comunidad, puede servir a otros. Lo
										llamamos filosofía <i>open-fit</i> y es el alma de YONDR.
									</p>
									<p>
										Puedes compartir entrenos, planes y ejercicios enviándolos
										dentro de la plataforma, o crear un enlace para que
										cualquiera que lo use, desde donde sea, pueda ver el
										contenido dentro de YONDR y guardárselo para poder usarlo.
									</p>
									<p>
										Sí, justo lo que estás pensando, podrás compartir contenido
										a través de instagram stories, whatsapp, correo
										electrónico...y la persona que use el enlace abrirá ese
										entreno o ese plan en YONDR.
									</p>
									<p>
										Se acabó el excel, word y pdf para compartir entrenos y
										planes.
									</p>
									<p>
										Se acabó mandar entrenos y planes por whatsapp en chorros de
										texto kilométricos.
									</p>
									<p>
										Se acabó publicar entrenos en instagram que tus seguidores
										no pueden usar.
									</p>
									<p>
										Bienvenido a <i>open-fit</i>. Bienvenido a YONDR.
									</p>
								</li>
								<li>
									<h3>ENTRENAR</h3>
									<p>
										Somos una plataforma de fitness y el entreno es nuestra
										forma de expresión.
									</p>
									<p>
										En YONDR puedes ejecutar entrenamientos, ayudándote de los
										cronómetros internos para controlar tus tiempos de ejecución
										y tiempos de descanso de cada serie.
									</p>
									<p>
										Por ejemplo, si estás haciendo una <i>Plancha Abdominal </i>
										podrás usar la cuenta atrás interna de la app para controlar
										el tiempo de ejecución de cada serie. Así no tendrás que
										estar peleándote con cronómetros externos y lo tendrás todo
										controlado desde YONDR. Lo mismo ocurre con los tiempos de
										descanso de cada serie.
									</p>
									<p>
										Supérate en cada entrenamiento y comparte la experiencia en
										redes.
									</p>
								</li>
								{/* <li>
                  <h3>PROGRESAR</h3>
                  <p>
                    Si mejoras tu, mejora el mundo. Por ello, ayudarte a
                    conseguir tus objetivos es el nuestro y divertirte en el
                    camino, es el nuestro.
                  </p>
                  <p>
                    Podrás crear registros de peso, índices, medidas... con
                    fotos asociadas para que no pierdas el foco de tu evolución.
                  </p>
                  <p>
                    Si eres del team POWER, podrás revisar tu progreso en
                    levantamientos como sentadilla, peso muerto, press
                    banca...accediendo a un histórico de todas tus repeticiones.
                  </p>
                </li> */}
							</ul>
						</div>
					</div>
					<div className='collapsible-block'>
						<div className='heading-wrapper'>
							<button type='button' class='collapsible-btn'>
								Es gratis, ¿Dónde está la trampa?
							</button>
							<PlusIcon className='plus-icon' />
						</div>
						<div class='content'>
							<p>Sencillo. No hay trampa.</p>
							<ul>
								<li>
									<p>
										Somos una plataforma social y no tendrás que pagar por una
										cuenta PREMIUM para desbloquear funcionalidades. TODO está
										disponible para TODOS.
									</p>
								</li>
								<li>
									<p>
										No verás publicidad que entorpezca tu experiencia en la
										plataforma.
									</p>
								</li>
								<li>
									<p>No comerciamos con tus datos</p>
								</li>
							</ul>
							<p>
								Entonces, ¿de dónde ganamos dinero? Sencillo también. No ganamos
								(de momento).
							</p>
							<p>
								A medida que la plataforma crezca, ofreceremos un nivel más de
								experiencia fitness en la plataforma que permitirá obtener una
								fuente de ingresos necesaria para seguir haciendo que YONDR sea
								la mejor plataforma de fitness del mundo.
							</p>
							<p>Y hasta ahí podemos leer ;)</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default FAQScreen
