// General
import React, { Component } from 'react'

//UI
import './hypeScreen.scss'
import { YondrLogoTypo } from '../../resources/Logos'
import SocialMediaStrip from '../../components/SocialMediaStrip/SocialMediaStrip'

class HypeScreen extends Component {
	constructor(props) {
		super(props)
		this.state = {
			clicked: false,
			show: false,
		}
	}

	render() {
		return (
			<section id='hero-hype'>
				<div className='container'>
					<YondrLogoTypo className='yondr-logo-typo' />
					<h1>Bienvenido, a la familia fit</h1>
					<div className='animation-wrapper'>
						<div class='shapeshifter play' />
					</div>
					<p>Muy pronto. Síguenos para + info.</p>
					<SocialMediaStrip />
				</div>
			</section>
		)
	}
}

export default HypeScreen
