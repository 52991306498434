// General
import React, { useState } from 'react'

//UI
import './contactScreen.scss'

//Components
import { MailIcon } from '../../resources/Icons'

function ContactScreen() {
	const handleSendMessage = () => {
		sendMail()
	}
	const sendMail = () => {
		// var message = document.getElementById("user-text-area");
		var link =
			'mailto:hola@yondr.es' +
			'?cc=' +
			'&subject=' +
			encodeURIComponent('Hola YONDR quería comentarte que...') +
			'&body=' +
			encodeURIComponent('')
		window.location.href = link
	}

	return (
		<>
			<section className='section' id='contact'>
				<div className='container'>
					<h1>SOPORTE</h1>
					<h2>
						Si necesitas ayuda con la plataforma, ponte en contacto con nosotros
						a través de nuestro correo y estaremos contigo a la mayor brevedad
						posible.
					</h2>
					<div class='nav-item'>
						<a
							className='btn--icon-rounded --small'
							id='mail-button'
							onClick={() => handleSendMessage()}
						>
							<MailIcon />
						</a>
						<a onclick={() => handleSendMessage()}>hola@yondr.es</a>
					</div>
				</div>
			</section>
		</>
	)
}

export default ContactScreen
