import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { BASE_URL } from '../../../ApiUrls'
import './AccountChangeMailScreen.scss'

async function changeEmailConfirm(key) {
	// Default options are marked with *
	const response = await axios.post(
		BASE_URL + '/users/change-email-confirmation',
		{ key: key }
	)
	return response
}

function AccountMailChangeScreen() {
	const [isDataFetched, setIsDataFetched] = useState(false)
	const [isConfirmed, setIsConfirmed] = useState(false)

	useEffect(() => {
		function getQueryVariable(variable) {
			var query = window.location.search.substring(1)
			var vars = query.split('&')
			for (var i = 0; i < vars.length; i++) {
				var pair = vars[i].split('=')
				if (pair[0] === variable) {
					return pair[1]
				}
			}
			return false
		}
		changeEmailConfirm(getQueryVariable('key'))
			.then(() => {
				setIsConfirmed(true)
			})
			.catch(() => {
				setIsConfirmed(false)
			})
			.finally(() => setIsDataFetched(true))
	}, [])
	return (
		<div className='container' id='change-mail-container'>
			<div className='wrapper'>
				{isDataFetched && (
					<>
						{isConfirmed ? (
							<div id='confirm'>
								<h2 className='subtitle'>
									El email se ha actualizado con éxito.
								</h2>
							</div>
						) : (
							<div id='wrong'>
								<h2 className='subtitle'>
									Ups...parece que ha habido un error en el proceso. Si se
									repite ponte en contacto con{' '}
									<a className='support-link' href='/contact'>
										soporte
									</a>
									.
								</h2>
							</div>
						)}
						<div className='signature'>Equipo YONDR</div>
					</>
				)}
			</div>
		</div>
	)
}

export default AccountMailChangeScreen
