// General
import React from 'react'

// Routing
import { BrowserRouter } from 'react-router-dom'
// import { RootRouter } from "./routing/router";
import { RootRoutes } from './routing/router'
import { HypeRoutes } from './routing/router'

//Components
import HypeScreen from './screens/hype/HypeScreen'
import NavBar from './components/NavBar/NavBar'
import Footer from './components/Footer/Footer'

import LandingScreen from './screens/landing/LandingScreen'

//UI
import './App.scss'
import '../src/resources/fonts/stylesheet.css'

import Hotjar from '@hotjar/browser'

const siteId = 3507813
const hotjarVersion = 6

Hotjar.init(siteId, hotjarVersion)

function App() {
	return (
		<BrowserRouter>
			<NavBar />
			<div id='page-container'>
				<div id='content-wrap'>
					{/* <LandingScreen /> */}
					<RootRoutes />
				</div>
			</div>
			<Footer id='footer' />
		</BrowserRouter>
	)
}

export default App
