// General
import React, { useEffect } from 'react'

//UI
import './workWithUsScreen.scss'

// Components
import {
	HelloIcon,
	GoalsIcon,
	ScreenIcon,
	MailOutlinedIcon,
	PlusIcon,
} from '../../resources/Icons'

function WorkWithUsScreen() {
	useEffect(() => {
		var coll = document.getElementsByClassName('collapsible-block')
		var i

		for (i = 0; i < coll.length; i++) {
			coll[i].addEventListener('click', function () {
				this.classList.toggle('active')
			})
		}
	})

	return (
		<>
			<section className='section' id='wwu-header'>
				<div className='container'>
					<h1>HAZ HISTORIA CON NOSOTROS</h1>
					<p>
						La mejor plataforma del mundo del fitness necesita el mejor talento.
						Te buscamos a ti.
					</p>
					<div className='section-divider-down-arrow --vidrio' />
				</div>
			</section>
			<section id='wwu-open-roles'>
				<div className='container --full-width'>
					<div id='guidelines'>
						<h2>Para que te conozcamos...</h2>
						<ul>
							<li>
								<div id='icon-container'>
									<HelloIcon className='icon' />
								</div>
								<div id='tip'>
									<p>Dinos qué te motiva a trabajar en YONDR</p>
								</div>
							</li>
							<li>
								<div id='icon-container'>
									<GoalsIcon className='icon' />
								</div>
								<div id='tip'>
									<p>Cuéntanos qué experiencias profesionales te representan</p>
								</div>
							</li>
							<li>
								<div id='icon-container'>
									<ScreenIcon className='icon' />
								</div>
								<div id='tip'>
									<p>
										Compártenos todo el material que nos permita descubrir tu
										talento (portfolio, web personal, instagram, linkedin, CV…)
									</p>
								</div>
							</li>
							<li>
								<div id='icon-container'>
									<MailOutlinedIcon className='icon' />
								</div>
								<div id='tip'>
									<p>
										Escríbenos a <b>hola@yondr.es</b> con la posición en el{' '}
										<i>asunto</i>.
									</p>
								</div>
							</li>
						</ul>
					</div>
					<div id='open-roles'>
						<h2>Posiciones abiertas</h2>
						<div className='collapsible-block'>
							<div className='heading-wrapper'>
								<button type='button' class='collapsible-btn'>
									UX/UI manager
								</button>
								<PlusIcon className='plus-icon' />
							</div>
							<div class='content'>
								<p>
									Buscamos un perfil con experiencia que lidere la estrategia UX
									y UI de YONDR.
								</p>
								<p>Te encargarás de:</p>
								<ul>
									<li>
										<p>
											Analizar las dinámicas y los flujos de interacción de los
											usuarios.
										</p>
									</li>
									<li>
										<p>
											Definir patrones de usabilidad que se alineen con el
											diseño y la estrategia de negocio.
										</p>
									</li>
									<li>
										<p>
											Diseñar la interfaz de usuario que se ajuste a los
											criterios de usabilidad definidos en la estrategia.
										</p>
									</li>
									<li>
										<p>
											Inspirar, liderar al equipo de UX/UI y hacer que crezca.
										</p>
									</li>
								</ul>
								<p>
									Si crees que puedes que encajas en nuestro proyecto, ponte en
									contacto con nosotros.
								</p>
							</div>
						</div>
						{/* <div className="collapsible-block">
              <div className="heading-wrapper">
                <button type="button" class="collapsible-btn">
                  Community Manager
                </button>
                <PlusIcon className="plus-icon" />
              </div>
              <div class="content">
                <p>Lorem ipsum...</p>
              </div>
            </div> */}
					</div>
				</div>
			</section>
		</>
	)
}

export default WorkWithUsScreen
