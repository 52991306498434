import React from 'react'

import DownloadStrip from '../../components/DownloadStrip/DownloadStrip'
import { YondrLogoTypo } from '../../resources/Logos'

import './downloadScreen.scss'

function DownloadScreen() {
	return (
		<div id='dowload-screen'>
			<div className='container'>
				<div className='caption'>
					<YondrLogoTypo className='yondr-logo-typo --mobile' />
					<h1>Fitness con amigos</h1>
					<h2>Descubre, entrena y comparte</h2>
					<div className='download-block'>
						!Descárgala y únete!
						<DownloadStrip />
					</div>
				</div>
			</div>
		</div>
	)
}

export default DownloadScreen
