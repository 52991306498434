// General
import React from 'react'

//UI
import './yondrScreen.scss'

// Components

function YondrScreen() {
	return (
		<>
			<section className='section' id='yondr-header'>
				<div className='container'>
					{/* <h1>CONOCE NUESTRO PROYECTO</h1> */}
					{/* <p>
            La mejor plataforma del mundo del fitness necesita el mejor talento.
            Te buscamos a ti.
          </p> */}
					<div className='section-divider-down-arrow --vidrio' />
				</div>
			</section>
			<section id='yondr-pitch'>
				<div className='container'>
					<h2>
						YONDR nace en 2021 con el objetivo de cubrir una necesidad que la
						comunidad fitness estaba reclamando. Una plataforma para gestionar y
						compartir contenido de entrenamiento y nutrición de una manera
						personalizada y fácil de utilizar.
					</h2>
					<h2>
						Apostamos por crear una app en la que nuestros usuarios formen parte
						de una familia en la que puedan crear, compartir y planificar su
						propio contenido en función de sus objetivos.
					</h2>
				</div>
			</section>
			{/* <section id="yondr-claim">
        <div className="container">
          <h1>CREA. ENTRENA. COMPARTE.</h1>
        </div>
      </section> */}
			{/* <section id="yondr-team">
        <div className="container --full-width"></div>
      </section> */}
			<section id='yondr-name'>
				<div className='container'>
					<h2>RECUERDA NUESTRO NOMBRE. SOMOS YONDR.</h2>
					<p>
						El origen de nuestro nombre tiene como punto de partida el
						inconformismo. Ese afán de superación y mejora continua es lo que
						nos lleva a ir más allá, a plantearnos nuevos retos que afrontar,
						nuevas metas que conseguir. Por ello trabajamos en torno al término
						inglés Beyond, un término comprensible en todo el mundo y con el
						significado que queremos transmitir, y dimos con Yonder, un sinónimo
						menos utilizado, más diferenciador. YONDR es el resultado de
						trabajar ese término, revisándolo y adaptándolo al entorno digital
						donde nace cualquier app, un término fresco y moderno que mantiene
						la esencia del proyecto.
					</p>
				</div>
			</section>
			<section id='yondr-values'>
				<div className='container'>
					<h2>NUESTROS VALORES</h2>
					<p>
						Creemos en un estilo de vida activo y saludable que nos ayuda a
						sentirnos fuertes y sanos en nuestro día a día.
					</p>
					<p>
						Somos inconformistas, lo que nos lleva a marcarnos constantemente
						nuevos objetivos de mejora que cumplimos gracias a una combinación
						de esfuerzo, compromiso y dedicación.
					</p>
					<p>
						Creamos YONDR gracias a la experiencia de un equipo. Conocemos el
						Fitness, su comunidad, necesidades e inquietudes.
					</p>
				</div>
			</section>
		</>
	)
}

export default YondrScreen
