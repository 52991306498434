import React from 'react'
import './AccountPasswordResetScreen.scss'

function AccountPasswordResetConfirmation() {
	return (
		<div className='container' id='password-reset-container'>
			<div className='wrapper'>
				<div id='confirm'>
					<h2 className='subtitle'>
						Tu contraseña se ha actualizado correctamente.
					</h2>
				</div>
			</div>
		</div>
	)
}

export default AccountPasswordResetConfirmation
