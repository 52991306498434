// General
import React, { useState } from 'react'

// UI/UX
import './footer.scss'
import SocialMediaStrip from '../SocialMediaStrip/SocialMediaStrip'
import DownloadStrip from '../../components/DownloadStrip/DownloadStrip'
import { YondrLogoPref } from '../../resources/Logos'
import { MailIcon } from '../../resources/Icons'
import downloadOnAppStore from '../../resources/logos/Download-on-app-store-white.svg'
import downloadInGooglePlay from '../../resources/logos/Download-in-google-play-clear.svg'

// Data
// import { MenuItems } from "./MenuItems";

export default function Footer() {
	const [isClicked, setIsClicked] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleClick = () => {
		setIsClicked(!isClicked)
	}

	const handleSendMessage = () => {
		sendMail()
	}

	const sendMail = () => {
		// var message = document.getElementById("user-text-area");
		var link =
			'mailto:hola@yondr.es' +
			'?cc=' +
			'&subject=' +
			encodeURIComponent('Hola YONDR quería comentarte que...') +
			'&body=' +
			encodeURIComponent('')
		window.location.href = link
	}

	return (
		<footer className='footer'>
			<div className='container'>
				<div className='brand-logo'>
					<a href='/'>
						<YondrLogoPref className='yondr-logo-pref --clear' />
					</a>
				</div>
				<nav className='nav'>
					<ul className='nav-list nav-list-larger'>
						<section className='nav-section'>
							<h3 className='section-title'>APP</h3>
							<li className='nav-item'>
								<a href='/faq'>FAQ</a>
							</li>
							<li className='nav-item'>
								<a href='/tutorials'>Tutoriales</a>
							</li>
							<li className='nav-item'>
								<a href='/terms'>Condiciones de uso</a>
							</li>
						</section>
						<section className='nav-section'>
							<h3 className='section-title'>NOSOTROS</h3>
							<li className='nav-item'>
								<a href='/yondr'>Yondr</a>
							</li>
							<li className='nav-item'>
								<a href='/work-with-us'>Trabaja con nosotros</a>
							</li>
							{/* <li className="nav-item">
                <a href="/">Inversión</a>
              </li> */}
							{/* <li className="nav-item">
                <a href="/">Brand kit</a>
              </li> */}
						</section>
						<section className='nav-section'>
							<h3 className='section-title'>CONTACTO</h3>
							<div className='contact-mail-wrapper'>
								<li className='nav-item' id='mail'>
									<a
										className='btn--icon-rounded --extra-small'
										id='mail-button'
										onClick={() => handleSendMessage()}
									>
										<MailIcon />
									</a>
									<a onClick={() => handleSendMessage()}>hola@yondr.es</a>
								</li>
							</div>
						</section>
						<section className='nav-section'>
							<h3 className='section-title'>DESCÁRGALA</h3>
							<li className='nav-item'>
								<a href='itms-apps://apple.com/app/id1536111702'>
									<img src={downloadOnAppStore} className='store-badge' />
								</a>
							</li>
							<li className='nav-item'>
								<a href='https://play.google.com/store/apps/details?id=yondr.es.android.prod'>
									<img src={downloadInGooglePlay} className='store-badge' />
								</a>
							</li>
						</section>
						<section className='nav-section'>
							<h3 className='section-title'>SÍGUENOS</h3>

							<li className='nav-item'>
								<SocialMediaStrip />
							</li>
						</section>
					</ul>
				</nav>
				<div className='copyright'>Copyright © 2020 YONDR.</div>
			</div>
		</footer>
	)
}
